import {
  EntityReviewStatus,
  EntityValidationStatus,
} from '@constants/canvas/layers';
import { ExportLayerKeys } from '@constants/entities/reports';
import { WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project';
import { useProjectFilters } from '@context/ProjectFilters';
import { useGenerateLayersReportMutation } from '@store/services/reports';
import { add } from '@utils/drawers';
import { getLayersReportFileName } from '@views/ReportsModel/helpers';
import useExportState from '@views/ReportsModel/hooks/useExportState';

const useExportLayers = (projectId: string, layers: ExportLayerKeys[]) => {
  const { isError, setError, fileContent, setFileContent } = useExportState();
  const [generateLayersReport, { isLoading, reset }] =
    useGenerateLayersReportMutation();

  const { reviewStatus, validationStatus, ciaFilters } = useProjectFilters();
  const {
    toolbox: { domains },
  } = useProject();

  const handleGenerate = async () => {
    try {
      const excelData = await generateLayersReport({
        projectId,
        layers,
        ...add(!!domains.length, {
          domains: domains.map((d) => (d === WideDomainKey ? 'null' : d)),
        }),

        ...add(!!reviewStatus.length, {
          review_required: reviewStatus.includes(
            EntityReviewStatus.ReviewRequired,
          ),
        }),

        ...add(!!validationStatus.length, {
          valid_status: validationStatus as EntityValidationStatus[],
        }),

        cia: JSON.stringify({
          ...ciaFilters.assurance_level,
          ...ciaFilters.coverage,
          ...ciaFilters.applicability,
        }),
      }).unwrap();

      setFileContent(excelData);
    } catch (e) {
      setError(true);
    }
  };

  const fileName = getLayersReportFileName(layers);

  return {
    isError,
    fileContent,
    isLoading,
    setFileContent,
    setError,
    handleGenerate,
    fileName,
    reset,
  };
};

export default useExportLayers;
